import React, { useEffect } from "react";
import {
    Grid,
    Typography,
    Container,
    Button,
    Paper,
    Box,
    Avatar,
    Divider,
    Input
} from "@material-ui/core";
import '../../assets/Community.scss';
import ArrowBack from "@material-ui/icons/ArrowBack";
import SendIcon from '@material-ui/icons/Send';
import { LikeButton } from '../../../../components/src/LikeButton';
import { AllCommentAttributes } from '../CommunityForumController';
import { MediaGrid, MediaWithoutGrid, MediaWithGrid } from './Media.web';
import { t } from "i18next";
import TranslationCore from './TranslationCore.web'
import FilterTranslateDesc from './FilterTranslation.web'
import { LikeShareButton } from "../../../../components/src/LikeShareButton";

const PostDetails = (
    {
        state,
        authorId,
        onCommentHandler,
        onMediaModalHandler,
        onCommentChange,
        onLikeHandler,
        handlePostDetailsBack,
        onReportModalClickHandler,
        handleConfirmModalOpen,
        onShareInputHandler,
        onReplyHandler,
        onRepliedHandler,
        onClickCommentIndex,
        onClickReplyComment,
        commentBoxVisibilityHandler,
        onCommentDeleteHandler,
        handleTransBackToOriginal,
        handleTranslation,
        allDownloadHandler,
        playVideo,
        playAudio,
        onEditHandler,
        onDeleteHandler,
        onrhDescriptionChange,
        onrhLanguageChange,
        handleOnShareToResourceHub,
        languages
    }: any) => {
    const MediaBox = (mediaArr: any) => {
        return (
            <>
                {mediaArr.length > 0 && mediaArr.length >= 5
                    && mediaArr.slice(0, 4).map((item: any, idx: number) => (
                        <MediaGrid mediaArr={mediaArr} items={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
                {mediaArr.length === 1 &&
                    mediaArr.map((item: any, idx: number) => (
                        <MediaWithoutGrid mediaArr={mediaArr} item={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))
                }
                {mediaArr.length < 5 && mediaArr.length > 1 &&
                    mediaArr.map((item: any, idx: number) => (
                        <MediaWithGrid mediaArr={mediaArr} itemData={item} idx={idx} onMediaModalHandler={onMediaModalHandler} playVideo={playVideo} playAudio={playAudio} />
                    ))}
            </>
        )
    }
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    let mediaArr: any = [];
    mediaArr = [...state.postDetails.attributes.images, ...state.postDetails.attributes.videos, ...state.postDetails.attributes.audios, ...state.postDetails.attributes.documents];
    let filter = []
    filter = state.translationArray?.filter((i: any) => i.id == state.postDetails.id).map((i: any) => i.data)
    const description = state.postDetails.attributes.description;
    const words = description.split(' ');

    return (
        <Container className="postDetailsContainer">
            <Paper className="postDetailsBlock" style={{marginBottom : state.isCommentEnable ? '0' : '20px'}}>
                <div className="backBtnBlock">
                    <Button onClick={handlePostDetailsBack} className="backbtn"><ArrowBack className="backbtnicon" />{t('Back')}</Button>
                </div>
                <Box className="recentPostCard">
                    <div className="recentPostBox">
                        <Avatar src={state.postDetails.attributes.author_image} />
                        <div className="descriptionBox">
                            <div className="desc">
                                <Typography className="descTitle">
                                    {state.postDetails.attributes.author_name}
                                </Typography>
                                <div className="tag">{state.postDetails.attributes.created_at}</div>
                            </div>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {words.map((word: string, index: number) => {
                                    const isHashtag = word.startsWith('#');

                                    return (
                                        <span
                                            key={index}
                                            className={isHashtag ? 'descText_hash' : 'descText_nonHash'}
                                            style={{ marginRight: '4px' }}>
                                            {word}
                                        </span>
                                    );
                                })}
                            </div>
                            {
                                state.postDetails.attributes.description?.length > 0 ?
                                    <TranslationCore item={state.postDetails} filter={filter} handleTranslation={handleTranslation} handleTransBackToOriginal={handleTransBackToOriginal} />
                                    : null
                            }
                            <div className="recentTags">
                            </div>
                        </div>
                        <div className='report-btn' onClick={() => onReportModalClickHandler(state.postDetails.id)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                            >
                                <path
                                    d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                    fill="#64748B" />
                            </svg>
                        </div>
                        {state.isReportModal && state.reportIndex === state.postDetails.id &&
                            <Box test-id="reportbox01" className='report-modal'>
                                <Typography className='report-text' onClick={() => allDownloadHandler(mediaArr)}>{t('Download')}</Typography>
                                {
                                    state.postDetails.attributes.author_id == authorId &&
                                    <>
                                        <Divider style={{ width: "100%" }} />
                                        <Typography test-id="delete-id" className='report-text' onClick={() => onDeleteHandler(state.postDetails.attributes.id)}>{t("Delete")}</Typography>
                                        <Divider style={{ width: "100%" }} />
                                        <Typography test-id="edit-id" className='report-text' onClick={() => onEditHandler(state.postDetails, -1)}>{t('Edit')}</Typography>
                                    </>
                                }
                                {
                                    state.postDetails.attributes.author_id != authorId && 
                                    <>
                                        <Divider style={{ width: "100%" }} />
                                        <Typography test-id="report-click-id" onClick={() => handleConfirmModalOpen(state.postDetails.id)} className='report-text'>{t('Report')}</Typography>
                                    </>
                                }
                            </Box>
                        }
                    </div>

                    <div className='postImageContainer'>
                        <Grid container spacing={1} style={{ justifyContent: "center", padding: "0 20px" }}>
                            {MediaBox(mediaArr)}
                        </Grid>
                    </div>

                    <div className="likesCard">
                        <div className="likesCommentsContainer">
                            <Typography className="tag">
                                {state.postDetails.attributes.post_likes < 2 ? state.postDetails.attributes.post_likes + ` ${t('Like')}` : state.postDetails.attributes.post_likes + ` ${t('Likes')}`}
                            </Typography>
                            <Typography className="tag">-</Typography>
                            <Typography className="tag">
                                {state.postDetails.attributes.post_comments < 2 ? state.postDetails.attributes.post_comments + ` ${t('Comment')}` : state.postDetails.attributes.post_comments + ` ${t('Comments')}`}
                            </Typography>
                        </div>
                        <LikeShareButton
                            languages={languages}
                            shareHandler={() => onShareInputHandler(state.postDetails.attributes.id)}
                            isLike={state.postDetails.attributes.current_user_liked}
                            commentOnClick={() => commentBoxVisibilityHandler(-1)}
                            onClick={() => onLikeHandler(state.postDetails.id, state.postDetails?.attributes.current_user_liked, state.postDetails.attributes.current_user_liked_id)}
                            onrhDescriptionChange={onrhDescriptionChange}
                            onrhLanguageChange={onrhLanguageChange}
                            state={state}
                            authorId={authorId}
                            data={state.postDetails}
                            onSend={handleOnShareToResourceHub}
                        />
                    </div>

                    <Divider className="lineDivider" />
                   
                    {state.postDetails.attributes.all_comments?.data && state.postDetails.attributes.all_comments.data.length > 0 &&
                        state.postDetails.attributes.all_comments.data.map((itm: AllCommentAttributes, idx: number) => {
                            return (
                                <>
                                    <Box className="recentPostBox">
                                        <Avatar src={itm.attributes.author_image} />
                                        <div className="commentDetail">
                                            <div className="userNameComment">
                                                <Typography className="userText">{itm.attributes.author_name}</Typography>
                                                <Typography className="tag">
                                                    -
                                                </Typography>
                                                <Typography className="time">{itm.attributes.created_at}</Typography>
                                            </div>
                                            <Typography className="time break word-break">
                                                {itm.attributes.comment}
                                            </Typography>
                                            <div className='replied-box'>
                                                {itm.attributes.reply_counts && <Typography test-id="repiled-click-id" className="time reply-btn" onClick={() => onRepliedHandler(0, idx)}>{itm.attributes.reply_counts} {t('Replied')}</Typography>}
                                                <Typography test-id="reply-btn" className="time reply-btn" onClick={() => onReplyHandler(11, idx)}>{t('Reply')}</Typography>
                                                {
                                                    (itm.attributes.account_id == authorId || state.postDetails.attributes.author_id == authorId) &&
                                                    <Typography test-id="comment-dlt-btn" className="time reply-btn" onClick={() => onCommentDeleteHandler(itm.attributes.id)}>{t('Delete')}</Typography>
                                                }
                                            </div>
                                        </div>
                                    </Box>
                                    {
                                        !state.isCommentEnable && state.commentIndex === 11 && state.replyIndex === idx &&
                                        <Box className="doComment" style={{margin: '0 24px'}}>
                                            <Input
                                                className="inputField"
                                                test-id="comment22"
                                                id="input-with-icon-adornment"
                                                placeholder={t('Comment here')}
                                                value={state.commentActiveIndex === -1 ? state.commentInput : ""}
                                                onClick={() => onClickReplyComment(-1)}
                                                onChange={state.commentActiveIndex === -1 ? onCommentChange : undefined} />
                                            {state.commentActiveIndex === -1 ?
                                                <SendIcon
                                                    test-id="send-id"
                                                    style={{ cursor: "pointer", margin: "0 10px", fill: state.commentInput.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                                                    onClick={() => onCommentHandler(null, itm.attributes.id)} /> :
                                                <SendIcon
                                                    style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
                                                />}
                                        </Box>
                                    }
                                    {
                                        state.isReplied && state.repiedIndex === idx && itm.attributes.comments.data.length > 0 &&
                                        itm.attributes.comments.data.map((replyCmnt: any) => (
                                            <Box key={replyCmnt.id} className="recentReplyPostBox">
                                                <Avatar src={replyCmnt.attributes.author_image} />
                                                <div className="commentDetail">
                                                    <div className="userNameComment">
                                                        <Typography className="userText">{replyCmnt.attributes.author_name}</Typography>
                                                        <Typography className="tag">
                                                            -
                                                        </Typography>
                                                        <Typography className="time">{replyCmnt.attributes.created_at}</Typography>
                                                    </div>
                                                    <Typography className="time break word-break">
                                                        {replyCmnt.attributes.comment}
                                                    </Typography>
                                                    {
                                                        (replyCmnt.attributes.account_id == authorId || state.postDetails.attributes.author_id == authorId) &&
                                                        <Typography test-id="comment-id-dlt" className="time reply-btn break word-break" onClick={() => onCommentDeleteHandler(replyCmnt.attributes.id)}>
                                                            {t('Delete')}
                                                        </Typography>
                                                    }
                                                </div>
                                            </Box>
                                        ))
                                    }
                                </>
                            )
                        })
                    }
                </Box>
            </Paper>
            {state.isCommentEnable === true &&
                        <Box className="doComment" style={{margin: '0 0 20px'}}>
                            <Input
                                className="inputField"
                                test-id="comment"
                                id="input-with-icon-adornment"
                                placeholder={t('Comment here')}
                                value={state.commentActiveIndex === -1 ? state.commentInput : ""}
                                onClick={() => onClickCommentIndex(-1)}
                                onChange={state.commentActiveIndex === -1 ? onCommentChange : undefined} />
                            {state.commentActiveIndex === -1 ?
                                <SendIcon
                                    test-id="send-icon-id"
                                    style={{ cursor: "pointer", margin: "0 10px", fill: state.commentInput.length > 0 && state.isCommentActive ? '#5497FF' : "#94A3B8" }}
                                    onClick={() => onCommentHandler(state.postDetails.attributes.id, null)}
                                /> :
                                <SendIcon
                                    style={{ cursor: "pointer", margin: "0 10px", fill: "#94A3B8" }}
                                />
                            }
                        </Box>
                    }
        </Container>
    );
};

export default PostDetails;
