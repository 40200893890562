import React from "react";
import {
    TextField,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';

export const CustomTextField = styled(TextField)(() => ({
    '& fieldset': {
        border: 'none'
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment': {
        top: '8px',
        right: '9px',
        marginRight: '4px',
    },
    '& .MuiAutocomplete-popupIndicator': {
        '&.MuiAutocomplete-popupIndicatorOpen': {
            background: '#E8EBF0',
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
            height: '38px',
        },
    },
    '& .MuiAutocomplete-inputFocused': {
        '&:focus': {
            backgroundColor: '#E8EBF0',
            borderRadius: "4px",
        },

    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        paddingLeft: '12px'
    },
    '& .MuiAutocomplete-clearIndicator': {
        display: 'none',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
        borderRadius: '8px 8px 0 0'
    },
    "&.enableCont":
    {

        border: '1px solid #CBD5E1',
        borderBottom: 'none',
        borderRadius: '8px 8px 0 0',
    },
    "&.disableCont":
    {
        border: '1px solid #CBD5E1',
        borderRadius: '8px',
    },
}));

interface CompProps{
    params: any
    open: boolean
    label?: any
}
const CustomTextFieldComp = (props: CompProps) => {
    return (
        <CustomTextField {...props.params}
            label=""
            className={props.open ? "enableCont" : "disableCont"}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            placeholder={props.label || ""}
            InputProps={{
                ...props.params.InputProps,
                style: {
                    padding: '8px',
                    color: '#1E293B',
                    fontSize: '16px',
                    fontFamily: "Nunito-Regular",
                    fontWeight: 400,
                    lineHeight: '24px',
                },
            }}

        />
    )
}

export default CustomTextFieldComp