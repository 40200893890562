import React, { useState } from "react";
import {
    ListItemText,
    Box,
    Grid,
    FormControl,
    FormLabel,
    Divider,
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { t } from "i18next";
import CustomTextFieldComp from "./CustomTextFieldComp.web";
import { showError } from "./ShowError.web";
import { CustomPaperB } from "./CountryAutoComplete.web";

interface OptionsType {
    title: string
}
interface InterfaceProps {
    options: OptionsType[]
    title?: string;
    initialValue: any;
    onChange: Function;
    fieldName: any
    profileErrorField?: any
    errorKey?: string
    placeholder?: string
}

const CustomAutocomplete = (props: InterfaceProps) => {
    const [dropdown, setDropdown] = useState(false);

    return (
        <>
            <Grid item xs={12} lg={6} sm={12} md={6}>
                <FormControl fullWidth>
                    {
                        props.title && 
                    <FormLabel className="dropdown_title">
                        {t(`${props.title}`)}
                    </FormLabel>
                    }
                    <div>
                        <Autocomplete
                            id="combo-box-demo"
                            data-test-id='custom-auto-complete'
                            options={props.options}
                            getOptionLabel={(option) => option.title}
                            style={{
                                borderColor: '#475569'
                            }}
                            onChange={(event: any, value: any) => props.onChange(event, value, props.fieldName)}
                            open={dropdown}
                            value={props.initialValue}
                            onOpen={() => setDropdown(true)}
                            onClose={() => setDropdown(false)}
                            popupIcon={<ExpandMoreIcon />}
                            disablePortal
                            blurOnSelect
                            PaperComponent={({ children }) => {
                                return (
                                    <CustomPaperB >
                                        <Divider style={{
                                            marginTop: "4px",
                                            marginBottom: "12px",
                                            marginLeft: "12px",
                                            marginRight: "12px"
                                        }} />
                                        {children}
                                    </CustomPaperB>
                                );
                            }}
                            renderOption={({ title }) =>
                                <Box>
                                    <ListItemText
                                        primary={title}
                                        className="dropdown_options"
                                    />
                                </Box>
                            }
                            renderInput={(params) =>
                                <CustomTextFieldComp label={props.placeholder} params={params} open={dropdown} />}
                        />
                    </div>
                    {showError({
                        errorKey: props.errorKey,
                        profileErrorField: props.profileErrorField
                    })}                
                    </FormControl>
            </Grid>
        </>

    );
};

export default CustomAutocomplete;


