import React from "react";
import {
  Typography,
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Box,
} from "@material-ui/core";
import {moreHoriz} from '../assets';
import {ConfirmModalBox} from "./confirmModalBox.web"
import { t } from "i18next";
import { noDataFoundImg, noSearchDataFoundImg } from "../../../user-profile-basic/src/assets";

export const SuperAdminComponent = ({account_id, userId, index, handleMore, itemId}:any) => {
  if(account_id && account_id.toString() !== userId.toString()){
    return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
    onClick={(event) => handleMore(event, itemId, "open", "close")} />
  }else{
    return <></>
  }
}

export const AdminComponent = ({account_id, userId, group_admin, created_by_id, index, handleMore, itemId}:any) => {
  if((account_id && account_id.toString() !== userId.toString()) && 
  (account_id && account_id.toString() !== (group_admin && created_by_id && created_by_id.toString()))){
 return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
 onClick={(event) => handleMore(event, itemId, "open", "close")} />
} else{
 return <></>
 }
}

export const GroupAdminComponent = ({accountId, user_id, index, item_id, handleMore}:any) => {
  if(accountId && accountId.toString() !== user_id.toString()){
    return <img data-test-id={"isMoreOpen"+index} src={moreHoriz} style={{cursor:'pointer'}} 
    onClick={(event) => handleMore(event, item_id, "open", "close")} />
  } else{
    return <></>
  }
}

export const HandleMoreComponent = ({created_by_id, userId, account_id, index, handleMore, itemId, group_admin, admin, superAdmin}:any) => {
  if(superAdmin){
   return <SuperAdminComponent account_id={account_id} userId={userId} index={index} handleMore={handleMore} itemId={itemId} />
  } 
  else if(admin){
   return <AdminComponent account_id={account_id} userId={userId} group_admin={group_admin} created_by_id={created_by_id} index={index}
    handleMore={handleMore} itemId={itemId} />
  }
  else if(!group_admin){
    return <GroupAdminComponent accountId={account_id} user_id={userId} index={index} item_id={itemId} handleMore={handleMore} />
  } 
  else{
    return <></>
  }
}

export const GroupMembersData = ({searchInput="", groupMembersData, handleMore, handleRemoveMemberApi, handleReportApi, group, userId, admin,
  handleMakeOrRevokeGroupAdmin, superAdmin
}:any) => {
  return(
    <>
    {groupMembersData && groupMembersData.length > 0 ? (
       groupMembersData.map((item: any, index: number) => {
        return (
        <>
        <div key={item} style={{display:"flex", alignItems:"center", justifyContent:"space-between", position:"relative"}} >
            <div style={{display:"flex", alignItems:"center", gap:"16px"}}>
                           <Avatar
                            alt="Jack Sparrow"
                            src={item.photo}
                            style={{width:"56px", height:"56px"}}
                            />
                        <div>
                          <span style={{display:"flex", gap:"8px"}}>
                            <Typography style={styles.followlistitem}>
                              {item.full_name}
                            </Typography>
                            {item.superAdmin && (
                               <Typography style={styles.followlistitem1}>
                                {"(Super Admin)"}
                               </Typography>
                            )}
                            {!item.superAdmin && (
                                <Typography style={styles.followlistitem1}>
                                {item.group_admin ? "(Admin)" : ""}
                              </Typography>
                            )}
                            </span>
                            <Typography style={styles.textSecond}>
                               {item.email}
                            </Typography>
                        </div>
                       </div>
                       <div>
                        <HandleMoreComponent created_by_id={group && group.attributes && group.attributes.created_by_id}
                         userId={userId} account_id={item && item.account_id} index={index} handleMore={handleMore} itemId={item.id} 
                         group_admin={item.group_admin} admin={group.attributes.created_by_id == userId} superAdmin={group.attributes.created_by_id == userId}
                        />
                        <Menu
                          data-test-id={"simpleMenu"+index}
                          id="simple-menu"
                          anchorEl={item.isMoreOpen}
                          keepMounted
                          open={Boolean(item.isMoreOpen)}
                          onClose={(event) => handleMore(event, item.id, "close", "close")}
                          getContentAnchorEl={null}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                          }}
                        >
                          <MenuItem data-test-id={"report"+index} onClick={(event) => handleMore(event, item.id, "close", "report")}
                            disabled={item.isReported ? true : false}>
                              {item.isReported ? t("Reported") : t("Report")}
                           </MenuItem>
                          {(group.attributes.created_by_id == userId) && (
                            <MenuItem data-test-id={"remove"+index} onClick={(event) => handleMore(event, item.id, "close", "remove")}>{t("Remove")}</MenuItem>
                          )}
                          {(group.attributes.created_by_id == userId) && (
                             <MenuItem data-test-id={"makeOrRemove"+index} onClick={() => handleMakeOrRevokeGroupAdmin(group.id, item.account_id, 
                               item.makeOrRevokeGroupAdminText)}>
                               {item.makeOrRevokeGroupAdminText}
                              </MenuItem>
                          )}
                        </Menu>
            </div>
        </div>
        <Divider style={{marginTop:"16px", marginBottom:"16px"}} /> 
        <ConfirmModalBox item={item} index={index} handleMore={handleMore} handleRemoveMemberApi={handleRemoveMemberApi}
         handleReportApi={handleReportApi} group={group} />
        </>
    )})
    ) : (
      <Box
        style={{
          height: "400px",
          margin: "auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <img
          data-test-id="resource-image"
          className="no_data_found"
          src={searchInput !== "" ? noSearchDataFoundImg :noDataFoundImg}
        />
        <Typography variant="h6" className="no_data_found_text_list" style={{ marginTop: "0 !important" }}>
          {searchInput !== "" ? t('Ups!... no result found') :t('Ups!... no data found')}
        </Typography>
      </Box>
    )}
   </>
  )
}

const styles = {
    followlistitem:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "22px",
    },
    followlistitem1:{
      color: "var(--neutrals-cool-gray-600, #64748B)",
      fontFamily: "Nunito-Regular",
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "22px",
  },
    textSecond:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
    }
}