import React from "react";
import {
    Box,
    Typography,
    Divider,
    FormControl,
    TextField,
    Modal,
    Button,
    Snackbar,
    Backdrop,
    CircularProgress,
    Chip,
    Portal
  } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import {addGroup, arrowBack, groupIcon, search} from '../assets';
import {GroupMembersData} from "./groupMembersData.web"
import {OtherMembers} from "./otherMembers.web"

import GroupMembersController, {
    Props,
  } from "../GroupMembersController.web";
import { ResponsiveRecentGroups } from "./responsiveRecentGroups.web";
import { t } from "i18next";
import PendingRequestModal from "../../../requestmanagement/src/components/PendingRequestModal.web";
  
  export default class GroupMembers extends GroupMembersController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }
  
    // Customizable Area Start
    // Customizable Area End

    render() {
      const {groupMembersData, searchInput, searchToggle, isModalOpen, copied, textToCopy, userId, admin, superAdmin, isOtherMembersOpen, 
        otherMembersData, isLoading} = this.state
      const {name, group_members, request_count, created_by_id, } = this.props.group.attributes;
        return(
            <>
                        <Box className="groupListBox">
                          <Box className="recentPostsCards">
                            <div style={{display:'flex', gap:"8px"}}>
                             <img data-test-id="handleOpen" style={{cursor:"pointer"}} src={arrowBack} onClick={() => this.props.handleOpen("AccountGroups")} />  
                             <Typography style={styles.groupName} className="">{name}</Typography>
                            </div>
                            <div style={{display:'flex', justifyContent:"space-between", marginTop:"8px", alignItems: 'center'}}>
                            <div style={{display:'flex', marginLeft:"30px", gap:"8px"}}>
                             <img src={groupIcon} />
                             <Typography style={styles.membersCount}>{`${group_members} ${group_members > 1 ? t("members") : t("member")}`}</Typography>
                            </div>
                            <div>
                    {userId == created_by_id && <>
                        <Chip
                          onClick={() => {
                            this.getPendingMemberRequestsList(this.props.group.id)
                            this.setState({pendingListModalState: true})
                          }}
                          style={{marginBottom: '14px'}}
                          className="pending_requests_chip"
                          label={request_count < 100 ? `+${request_count} Pending Requests` : `99+ Pending Requests`}
                        /></>}
                        {this.state.pendingListModalState && (
                            <PendingRequestModal
                              open={this.state.pendingListModalState}
                              data-test-id={'pending-modal'}
                              navigation={this.props.navigation}
                              modalCloseFn={()=>{this.setState({pendingListModalState: false})}}
                              membersList={this.state.pendingRequestList}
                              rejectPendingRequestFunction={this.rejectPendingRequest}
                              acceptPendingRequestFunction={this.acceptPendingRequest}
                              createHoldMessageRequest={this.createHoldMessageRequest}
                              messageList={this.state.messageList}
                              groupId={this.props.group.id}
                            />
                        )}
                              {(admin || superAdmin) && (
                                 <img data-test-id="modalOpen" src={addGroup} style={{cursor:"pointer"}} 
                                //  onClick={() => this.handleOtherMembers()} 
                                 />
                              )}
                              {!isOtherMembersOpen && (
                                    <img data-test-id="searchToggle" src={search} style={{marginLeft:"16px", cursor:"pointer"}} 
                                    onClick={() => this.handleClick("searchToggle", "close")} />
                              )}
                            </div>
                            </div>
                            {
                              searchToggle && (
                                <FormControl fullWidth style={{marginTop:"16px"}}>
                                <TextField
                                    data-test-id="searchInput"
                                    value={searchInput}
                                    type={'text'}
                                    placeholder={t("Search...")}
                                    variant="outlined"
                                    name='group_name'
                                    className="text-box"
                                    onChange={(event) => this.handleSearch(event)}
                                />
                              </FormControl>
                              )
                            }
                            <Divider style={{marginTop:"16px", marginBottom:"16px"}} /> 
                            {isOtherMembersOpen ? (
                              <OtherMembers otherMembersData={otherMembersData} handleSendInvite={this.handleSendInvite} />
                            ) : (
                              <GroupMembersData searchInput={searchInput} groupMembersData={groupMembersData} handleMore={this.handleMore}
                              handleRemoveMemberApi={this.handleRemoveMemberApi} handleReportApi={this.handleReportApi} group={this.props.group}
                              userId={userId} admin={admin} handleMakeOrRevokeGroupAdmin={this.handleMakeOrRevokeGroupAdmin} superAdmin={superAdmin}
                              />
                            )}
                          </Box>
                        </Box>
                        <div style={{marginTop:"16px"}}>
                        <ResponsiveRecentGroups navigation={""} id={""} handleOpen={this.props.handleOpen} groups={this.props.groups}
                       handleJoinGroup={this.props.handleJoinGroup} getGroupDetails={this.props.getGroupDetails} recentGroups={this.props.recentGroups} />    
                        </div> 
             <Modal
              data-test-id="modal"
              open={isModalOpen}
              onClose={() => this.handleClick("isModalOpen", "close")}
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  backgroundColor: 'white',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                  padding: '16px',
                  textAlign: 'center',
                  borderRadius:"5px",
                  width:"100%",
                  maxWidth:"500px"
                }}
                className="confirm-modal"
              >
                 <FormControl fullWidth>
                                <TextField
                                    value={textToCopy}
                                    type={'text'}
                                    variant="outlined"
                                    name='group_name'
                                    className="text-box"
                                    aria-readonly
                                />
                              </FormControl>
                              <div style={{marginTop:"10px", float:"right"}}>
                              <Button style={{borderRadius:"5px", color:"white"}} data-test-id="close" variant="contained" color="secondary" 
                              onClick={() => this.handleClick("isModalOpen", "close")}>
                    {t("Close")}
                  </Button>   
                  <Button style={{marginLeft:"10px"}} data-test-id="copy" variant="contained" color="primary" onClick={() => this.copyToClipboard()}>
                  {copied ? t("Copied") : t("Copy Link")}
                  </Button> 
                </div>
                </div>
              </Modal>
            <Portal>
            <Snackbar
              style={{ zIndex: 9999 }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.showSnackbars}
              autoHideDuration={1500}
              onClose={this.handleCloseSnackbar}
            >
              <MuiAlert
                onClose={this.handleCloseSnackbar}
                severity={this.state.severitys}
              >
                {this.state.messages}
              </MuiAlert>
            </Snackbar>
            </Portal>
            <Backdrop className="backdrop-loader" open={isLoading} >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
          )
    }
}

const styles = {
    groupContainer:{
      display:'flex',
      alignItems: 'center',
      justifyContent:'space-between',
      marginTop: '55px',
      width: "100%",
    },
    groupName:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Comfortaa-Bold",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "26px",
    },
    membersCount:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "18px",
        marginTop:"6px"
    },
    followlistitem:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Comfortaa-Bold",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
    },
    textSecond:{
        color: "var(--neutrals-cool-gray-600, #475569)",
        fontFamily: "Nunito-Regular",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
    }
}