import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken } from "../../../components/src/NativeWebRouteWrapper/Utils";
import {t} from "i18next"

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleOpen:any;
  group:any;
  groups:any;
  handleJoinGroup:any;
  getGroupDetails:any;
  recentGroups:any;
  resetGroupDetails: any
  // Customizable Area End
}

export interface S {
  id: string;
  // Customizable Area Start
  groupMembersData:any,
  searchInput:string,
  searchToggle:boolean,
  isModalOpen:boolean,
  copied:boolean,
  textToCopy:string,
  mId:string,
  userId:any;
  admin:boolean,
  groupId:any,
  showSnackbars: boolean,
  messages: string,
  severitys: any,
  text:string,
  group:any,
  superAdmin:boolean,
  otherMembersData:any,
  isOtherMembersOpen: boolean,
  isLoading:boolean,
  pendingRequestList:[],
  pendingListModalState: boolean
  showSnackbar: boolean;
  severity: any;
  message: string;

  roomId: string;
  messageList: any[];
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class GroupMembersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getGroupMembersApiCallId = ""
  searchApiCallId = ""
  removeMemberApiCallId = ""
  reportApiCallId = ""
  makeOrRevokeGroupAdminApiCallId = ""
  otherMembersApiCallId = ""
  sendInviteApiCallId = ""
  pendingMemberRequestApiCallId = ''
  acceptPendingRequestApiCallId = ''
  rejectPendingRequestApiCallId = ''
  token = getAuthToken();

  holdRequestApiCallId = ''
  chatMessagesApiCallId = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      id: "",
      groupMembersData:[],
      searchInput:"",
      searchToggle:false,
      isModalOpen: false,
      copied:false,
      textToCopy:"https://pba1-292270-react.b292270.dev.eastus.az.svc.builder.cafe/AccountGroups",
      mId:"",
      userId: "",
      admin:false,
      groupId:"",
      showSnackbars: false,
      messages: '',
      severitys: "",
      text:"",
      group:{},
      superAdmin:false,
      otherMembersData:[],
      isOtherMembersOpen:false,
      isLoading:false,
      pendingRequestList:[],
      pendingListModalState: false,
      showSnackbar: false,
      severity: undefined,
      message: "",
      roomId: "",
      messageList: []
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  async receive (from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch (apiRequestCallId) {
        case this.holdRequestApiCallId:
          this.holdRequestApiHandler(responseJson)
          break 
        case this.chatMessagesApiCallId:
          this.chatMessagesApiHandler(responseJson)
          break 
        case this.getGroupMembersApiCallId:
          this.getGroupMembersApiCallHandler(responseJson.data, this.state.userId)
        break  
        case this.searchApiCallId:
          this.getGroupMembersApiCallHandler(responseJson.data, this.state.userId)
        break  
        case this.removeMemberApiCallId:
         this.removeMemberApiCallHandler()
        break  
        case this.reportApiCallId:
          this.reportApiCallHandler(this.state.mId)
        break  
        case this.makeOrRevokeGroupAdminApiCallId:
          this.makeOrRevokeGroupAdminApiCallHandler(this.state.mId)
        break  
        case this.otherMembersApiCallId:
          const otherMembersData = responseJson.data.map((item:any) => {
            return {
              id: item.id,
              full_name: item.attributes.full_name,
              profession: item.attributes.profession,
              photo: item.attributes.photo,
              is_invited: item.attributes.invitation_status
            }
          })
          this.setState({otherMembersData, isLoading:false})
        break  
        case this.sendInviteApiCallId:
          this.sendInviteApiCallHandler(this.state.userId) 
        break 
        case this.pendingMemberRequestApiCallId:
          this.getPendingMemberRequestsListApiHandler(responseJson)
        break
        case this.acceptPendingRequestApiCallId:
          this.acceptPendingRequestApiHandler(responseJson)
        break
        case this.rejectPendingRequestApiCallId:
          this.rejectPendingRequestApiHandler(responseJson)
        break
      }
  }}

  sendInviteApiCallHandler = (userId:any) => {
    const otherMembersData = this.state.otherMembersData.map((item:any) => {
      if(item.id === userId){
        return {
          ...item,
          is_invited:true
        }
      }
      return item
    })
    this.setState({
      otherMembersData,
      showSnackbars: true,
      messages: t("Invite sent successfully"),
      severitys: "success"
    })
  }

  getGroupMembersApiCallHandler = (data:any, id:any) => {
    const {group} = this.props
    const groupMembersData = data && data.map((item:any) => {
      return {
        id: item.id,
        account_id: item.attributes && item.attributes.account_id,
        full_name: item.attributes.full_name,
        email: item.attributes.email,
        profession: item.attributes.profession,
        photo: item.attributes.photo,
        group_admin: item.attributes.is_admin,
        isMoreOpen: null,
        isRemoveModalOpen: false,
        isReportModalOpen: false,
        isReported:item.attributes.is_reported,
        makeOrRevokeGroupAdminText: item.attributes.is_admin ? t("Revoke Admin") : t("Make Admin")
      }
      })  
  
      this.setState({ groupMembersData: groupMembersData || [] }, () => {
        const updatedGroupMembersData = this.state.groupMembersData.map((ele: any) => {
          if (ele.group_admin && group && group.attributes 
              && group.attributes.created_by_id 
              && group.attributes.created_by_id.toString() === ele.account_id.toString()) {
            return { ...ele, superAdmin: true }
          }
      
          if (ele.group_admin && ele.account_id.toString() === id.toString()) {
            return { ...ele, admin: true }
          }
      
          return { ...ele, admin: false, superAdmin: false };
        });
        this.setState({ groupMembersData: updatedGroupMembersData });
      });
  }

  makeOrRevokeGroupAdminApiCallHandler = (id:any) => {
    const groupMembersData = this.state.groupMembersData.map((item:any) => {
      if(item.account_id === id){
        return {
          ...item,
          isMoreOpen: null,
          isRemoveModalOpen: false,
          isReportModalOpen: false,
          group_admin: !item.group_admin,
          makeOrRevokeGroupAdminText: item.group_admin ? t("Make Admin") : t("Revoke Admin")
        }
      }
      return item
      })  
    this.setState({
      groupMembersData,
      showSnackbars: true,
      messages: this.state.text === "Make Admin" ? t('Admin created successfully') : t("Admin revoked successfully"),
      severitys: "success"
    })  
  }

  removeMemberApiCallHandler = () => {
    const groupMembersData = this.state.groupMembersData.filter((item:any) => item.account_id !== this.state.mId)
    this.setState({
      groupMembersData, 
      showSnackbars: true,
      messages: t('Member removed successfully'),
      severitys: "success"
    })
  }

  reportApiCallHandler = (mId:any) => {
      const groupMembersData = this.state.groupMembersData.map((item:any) => {
        if(item.id === mId){
          return {
            ...item,
            isMoreOpen: null,
            isRemoveModalOpen: false,
            isReportModalOpen: false,
            isReported:true
          }
        }
        return item
      })
      this.setState({
        groupMembersData,
        showSnackbars: true,
        messages: t('Member reported successfully'),
        severitys: "success"
      })
  }

  async componentDidMount() {
    super.componentDidMount();
    window.scroll(0,0)
     const userInfo = JSON.parse(localStorage.getItem("userInfo")!)
     const groupId = localStorage.getItem("group_id")
     const group = JSON.parse(localStorage.getItem("new_user")!) || {}
     this.setState({userId: userInfo.id, groupId, group}, () => this.groupMembers())
  }

  createHoldMessageRequest = (body: {group_id: any, user_id: any, content: any}) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.holdRequestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/create_hold_conversation?group_id=${body.group_id}&user_id=${body.user_id}&content=${body.content}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  holdRequestApiHandler = (response: any) => {
    if (response && response.data) {
      this.setState({ roomId: response.data.room.data.id }, () => {
        this.getHoldMessageList();
      })
    }
  }

  getHoldMessageList = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.chatMessagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/list_messages?room_id=${this.state.roomId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  chatMessagesApiHandler = (response: any) => {
    if(response && response.data) {
      this.setState({messageList: response.data})
    }
  }

  groupMembers = () => {
        const header = {
          "Content-Type": configJSON.apiContentType,
          "token": this.token
        };
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getGroupMembersApiCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `account_groups/${this.state.groupId}/group_members`
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethod
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
  }

  handleSearch = (event:any) => {
    this.setState({searchInput: event.target.value}, () => this.handleSearchApi())
  }

  handleClick = (name:string, type:string) => {
    switch(name){
     case "searchToggle":
      this.setState({searchToggle : !this.state.searchToggle, searchInput:""})
     break 
     case "isModalOpen":
      if(type === "open"){
        document.body && document.body.classList.add('my-class')  
      } else{
        document.body && document.body.classList.remove('my-class')  
      }
      this.setState({isModalOpen : !this.state.isModalOpen, copied: false})
     break 
    }
  }

  groupMembersDataHandler = (event:any, id:any, type:string, modalType:string) => {
    const groupMembersData = this.state.groupMembersData.map((item:any) => {
      if(item.id === id){
        return {...item, isMoreOpen: type === "open" ? event.currentTarget : null,
         isRemoveModalOpen: modalType === "close" ? false : modalType === "remove" ? true : false,
         isReportModalOpen: modalType === "close" ? false : modalType === "report" ? true : false,
        }
      }
      return item
    })
  this.setState({groupMembersData})
  }

  handleMore = (event:any, id:any, type:string, modalType:string) => {
    if(type === "open"){
      document.body.classList.add('my-class')  
    } else{
      document.body.classList.remove('my-class')  
    }
     this.groupMembersDataHandler(event, id, type, modalType)
  }

  copyToClipboard = () => {
    navigator.clipboard && navigator.clipboard.writeText(this.state.textToCopy)
   this.setState({copied:true})
  };

  handleSearchApi = () => {  
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.searchApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `groups/${this.state.groupId}/search_grp_member?data[attributes][user_name]=${this.state.searchInput}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleReportApi = (groupId:any, accountId:any, itemId:any) => {  
    this.setState({mId:itemId})
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/${groupId}/report_on_group_member?data[attributes][account_id]=${accountId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleRemoveMemberApi = (groupId:any, itemId:any) => {  
   this.setState({mId:itemId}) 
   const header = {
      "Content-Type": configJSON.ApiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeMemberApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_groups/${groupId}/remove_member/${itemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleMakeOrRevokeGroupAdmin = (groupId:any, itemId:any, text:string) => {  
    this.setState({mId:itemId, text})
    const header = {
       "Content-Type": configJSON.ApiContentType,
       "token": this.token
     };
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );

     this.makeOrRevokeGroupAdminApiCallId = requestMessage.messageId;
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       text === "Make Admin" ? `account_groups/${groupId}/make_group_admin/${itemId}` : `account_groups/${groupId}/revoke_group_admin/${itemId}`
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       configJSON.putApiMethod
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
     return true;
  }

  handleCloseSnackbar = () => {
    this.setState({
      showSnackbars: false,
      severitys: "",
      messages: "",
    });
    return true;
  };

  handleOtherMembers = () => {
    this.setState({isOtherMembersOpen: !this.state.isOtherMembersOpen, isLoading:true})
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.otherMembersApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/${this.state.groupId}/other_members_list`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleSendInvite = (userId:any) => {
    this.setState({userId})
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendInviteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/send_invite/${this.state.groupId}/user/${userId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPendingMemberRequestsList = (groupID: any) => {
    const header = {
      "token": this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pendingMemberRequestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/${groupID}/pending_requests`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getPendingMemberRequestsListApiHandler = (responseJson: any) => {
    if (responseJson != null) {
      if (responseJson.pending_requests) {
        this.setState({ pendingRequestList: responseJson.pending_requests });
      } else {
        this.setState({ pendingRequestList: [], pendingListModalState: false })
      }
    }
  }
  

  acceptPendingRequest = (user_id: number) => {
    const header = {
      "token": this.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptPendingRequestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_groups/${user_id}/accept_private_group_request`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  acceptPendingRequestApiHandler = (responseJson: any) => {
      if (responseJson != null) {
        if (responseJson.message) {
          this.setState({
            showSnackbars: true,
            messages: t('Request accepted successfully'),
            severitys: "success"
          })
        this.getPendingMemberRequestsList(this.props.group.id) 
        this.props.resetGroupDetails()
        this.groupMembers()
        } else {
          return
        }
      }
  }

  rejectPendingRequest = (user_id: number, rejection_reason: string) => {
    const header = {
      "token": this.token
    };

    let formData = new FormData();
    formData.append('rejection_reason', rejection_reason);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.rejectPendingRequestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_groups/${user_id}/reject_private_group_request`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  rejectPendingRequestApiHandler = (responseJson: any) => {
      if (responseJson != null) {
        if (responseJson.message) {
          this.setState({
            showSnackbars: true,
            messages: t('Request rejected successfully'),
            severitys: "success"
          }) 
          this.getPendingMemberRequestsList(this.props.group.id) 
          this.props.resetGroupDetails()
          this.groupMembers()
        } else {
          return
        }
      }
    }
  // Customizable Area End
}
