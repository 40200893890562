import React, { useState } from "react";
import {
  Avatar,
  Button,
  Badge,
  Modal,
  Typography,
  Grid,
  Box,
  InputBase,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { t } from "i18next";
import "../../assets/RequestManagement.scss";
import moment from "moment";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 588,
  borderRadius: "8px",
  outline: "none",
  minWidth: 577,
  flexDirection: "column",
};

interface DeclineRequestModalTypes {
  open: boolean;
  modalCloseFn: any;
  userInfo: any;
  conversation: any[];
  createHoldMessageRequest: Function
  groupId: any
}

const HoldRequestModal = (props: DeclineRequestModalTypes) => {
  const [reason, setReason] = useState("");

  const currentUser = localStorage.getItem("userInfo");
  if (!currentUser) {
    return null;
  }

  const user = JSON.parse(currentUser)
  
  const handleSendMessage = () => {
    if (reason !== "") {
      props.createHoldMessageRequest({ group_id: props.groupId, user_id: props.userInfo.user_id, content: reason })
      setReason("")
    }
  }

  return (
    <Modal
      open={props.open}
      onClose={props.modalCloseFn}
      data-test-id="pending-modal-close-click"
    >
      <Box sx={style} className="decline_modal_box">
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Grid className="decline_box">
            <Box className="decline_container">
              <Badge
                style={{ cursor: "pointer" }}
                data-test-id="back-arrow-icon"
                onClick={props.modalCloseFn}
              >
                <ArrowBackIcon className="decline_arrow_button" />
              </Badge>
              <Typography variant="h6" className="decline_request_text">
                {t("Holding Request")}
              </Typography>
            </Box>
            <div onClick={props.modalCloseFn} style={{ cursor: "pointer" }}>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.2005 4.30713C12.0759 4.18229 11.9068 4.11214 11.7305 4.11214C11.5541 4.11214 11.385 4.18229 11.2605 4.30713L8.00047 7.56046L4.74047 4.30046C4.61591 4.17562 4.44681 4.10547 4.27047 4.10547C4.09412 4.10547 3.92502 4.17562 3.80047 4.30046C3.54047 4.56046 3.54047 4.98046 3.80047 5.24046L7.06047 8.50046L3.80047 11.7605C3.54047 12.0205 3.54047 12.4405 3.80047 12.7005C4.06047 12.9605 4.48047 12.9605 4.74047 12.7005L8.00047 9.44046L11.2605 12.7005C11.5205 12.9605 11.9405 12.9605 12.2005 12.7005C12.4605 12.4405 12.4605 12.0205 12.2005 11.7605L8.94047 8.50046L12.2005 5.24046C12.4538 4.98713 12.4538 4.56046 12.2005 4.30713Z"
                  fill="white"
                />
              </svg>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Box
            className="conversation-section"
            justifyContent={
              props.conversation.length > 0 ? "flex-start" : "center"
            }
          >
            {props.conversation.map((item: any, index: number) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={item.sender_id !== Number(user.id) ? "flex-start" : "flex-end"}
                  style={{ gap: "2px", color: "#475569", width: "100%" }}
                >
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    style={{ gap: "4px" }}
                  >
                    <Typography variant="body2" style={{ lineHeight: "28px" }}>
                      {item.user_name} -{" "}
                    </Typography>
                    <Typography variant="caption">{moment(item.created_at).fromNow()}</Typography>
                  </Box>
                  <Typography variant="caption">{item.content}</Typography>
                </Box>
              );
            })}
            {props.conversation.length === 0 && (
              <Box className="no_convo_container">
                <Typography variant="h6" className="no_convo_text">
                  {t(`No conversation`)}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className="holding_request_container">
            <Grid container className="reason_avatar_container">
              <Avatar
                src={user.photo}
                alt="Member Picture"
                className="reason_avatar"
              />
              <InputBase
                data-test-id="reason_input_base"
                className="reason_input_base"
                placeholder={t(`Reason for holding request`)}
                value={reason}
                onChange={(e: any) => setReason(e.target.value)}
                inputProps={{ maxLength: 1028 }}
                multiline={true}
              />
            </Grid>
            <Grid>
              <Button
                style={{ opacity: reason.length === 0 ? 0.5 : 1 }}
                className="reason-send-button"
                disabled={reason.length === 0}
                onClick={handleSendMessage}
              >
                {t(`Send`)}
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default HoldRequestModal;
