import React from "react";
import {
  Typography,
  Box,
  CardMedia,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import { t } from "i18next";
import {
  applicationNoFound,
  audioNoFound,
  imageNoFound,
  noRecordFound,
} from "../../../catalogue/src/assets";
import { ICard } from "../../../catalogue/src/CatalogueController";

export const GroupMediaData = ({
  groupMediaData,
  type,
  handlePostDetails,
  onDownloadHandler,
  selectedTab,
}: any) => {
  const imageTypeUrl = (cardValue: { url: string; type: string }) => {
    if (cardValue.url !== "" && cardValue.type === "image") {
      return cardValue.url;
    }
    if (cardValue.type === "image") {
      return imageNoFound;
    } else if (cardValue.type === "audio") {
      return audioNoFound;
    } else if (cardValue.type === "document") {
      return applicationNoFound;
    }
  };

  const iconTypeRender = (cardValue: ICard) => {
    if (cardValue.type === "image") {
      return (
        <ImageIcon
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
        />
      );
    } else if (cardValue.type === "video") {
      return (
        <PlayCircleFilledIcon
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
        />
      );
    } else if (cardValue.type === "audio") {
      return (
        <svg
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
          viewBox="0 0 40 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 32.7539C17.6667 32.7539 19.0833 32.1706 20.25 31.0039C21.4167 29.8372 22 28.4206 22 26.7539V12.7539H28V8.75391H19V21.5539C18.5333 21.2872 18.05 21.0872 17.55 20.9539C17.05 20.8206 16.5333 20.7539 16 20.7539C14.3333 20.7539 12.9167 21.3372 11.75 22.5039C10.5833 23.6706 10 25.0872 10 26.7539C10 28.4206 10.5833 29.8372 11.75 31.0039C12.9167 32.1706 14.3333 32.7539 16 32.7539ZM20 40.7539C17.2333 40.7539 14.6333 40.2289 12.2 39.1789C9.76667 38.1289 7.65 36.7039 5.85 34.9039C4.05 33.1039 2.625 30.9872 1.575 28.5539C0.525 26.1206 0 23.5206 0 20.7539C0 17.9872 0.525 15.3872 1.575 12.9539C2.625 10.5206 4.05 8.40391 5.85 6.60391C7.65 4.80391 9.76667 3.37891 12.2 2.32891C14.6333 1.27891 17.2333 0.753906 20 0.753906C22.7667 0.753906 25.3667 1.27891 27.8 2.32891C30.2333 3.37891 32.35 4.80391 34.15 6.60391C35.95 8.40391 37.375 10.5206 38.425 12.9539C39.475 15.3872 40 17.9872 40 20.7539C40 23.5206 39.475 26.1206 38.425 28.5539C37.375 30.9872 35.95 33.1039 34.15 34.9039C32.35 36.7039 30.2333 38.1289 27.8 39.1789C25.3667 40.2289 22.7667 40.7539 20 40.7539Z"
            fill="#5497FF"
          />
        </svg>
      );
    } else if (cardValue.type === "document") {
      return (
        <svg
          style={{
            height: "2rem",
            width: "2rem",
            marginRight: "8px",
            marginBottom: "2px",
            color: "#5497FF",
          }}
          viewBox="0 0 36 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 40C2.9 40 1.95833 39.6083 1.175 38.825C0.391667 38.0417 0 37.1 0 36V8C0 6.9 0.391667 5.95833 1.175 5.175C1.95833 4.39167 2.9 4 4 4H12.4C12.8333 2.8 13.5583 1.83333 14.575 1.1C15.5917 0.366667 16.7333 0 18 0C19.2667 0 20.4083 0.366667 21.425 1.1C22.4417 1.83333 23.1667 2.8 23.6 4H32C33.1 4 34.0417 4.39167 34.825 5.175C35.6083 5.95833 36 6.9 36 8V36C36 37.1 35.6083 38.0417 34.825 38.825C34.0417 39.6083 33.1 40 32 40H4ZM8 32H22V28H8V32ZM8 24H28V20H8V24ZM8 16H28V12H8V16ZM18 6.5C18.4333 6.5 18.7917 6.35833 19.075 6.075C19.3583 5.79167 19.5 5.43333 19.5 5C19.5 4.56667 19.3583 4.20833 19.075 3.925C18.7917 3.64167 18.4333 3.5 18 3.5C17.5667 3.5 17.2083 3.64167 16.925 3.925C16.6417 4.20833 16.5 4.56667 16.5 5C16.5 5.43333 16.6417 5.79167 16.925 6.075C17.2083 6.35833 17.5667 6.5 18 6.5Z"
            fill="#5497FF"
          />
        </svg>
      );
    }
  };

  return (
    <Grid container spacing={3}>
      {groupMediaData && groupMediaData?.length > 0 ? (
        groupMediaData.map(
          (data: any) =>
            data?.attributes?.[type].length > 0 &&
            data?.attributes?.[type].map((item: any, index: any) => {
              console.log(item, "media");

              return (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  style={{ height: "320px" }}
                >
                  <Card
                    key={item.attachment_id}
                    data-testid="handleMouseEvent"
                    className="custom-card"
                    style={{
                      height: "auto",
                      borderRadius: "8px",
                      backgroundColor:
                        "linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%",
                      boxShadow: "2px 0px 8px 3px #00000014",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        position: "relative",
                        borderRadius: "8px",
                        left: "0px",
                        top: "0px",
                      }}
                    >
                      {item.type !== "video" && (
                        <CardMedia
                          component="img"
                          style={{
                            height: "200px",
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                          }}
                          alt={`Image ${index + 1}`}
                          image={imageTypeUrl(item)}
                          title={`Image ${index + 1}`}
                        />
                      )}
                      {item.type === "video" && (
                        <Box style={{ position: "relative" }}>
                          <video
                            style={{
                              height: "200px",
                              width: "100%",
                              borderTopLeftRadius: "8px",
                              borderTopRightRadius: "8px",
                            }}
                          >
                            <source src={item.url} type="video/mp4" />
                          </video>
                        </Box>
                      )}
                    </Box>
                    <CardContent
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "16px",
                        width: "auto",
                        gap: "8px",
                      }}
                    >
                      <Box
                        className="icon-lang-container"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {iconTypeRender(item)}
                        <Typography
                          variant="h6"
                          style={{
                            color: "#475569",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        ></Typography>
                        <Typography
                          variant="body2"
                          className="button-card"
                          style={{
                            backgroundColor: "#F6F0FF",
                            color: "#475569",
                            borderRadius: "40px",
                            padding: "6px 8px",
                          }}
                        >
                          {item.language}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          width: "100%",
                          wordBreak: "break-word",
                          textOverflow: "ellipsis",
                          WebkitLineClamp: 2,
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          display: "-webkit-box",
                          minHeight: "2.2rem",
                          lineHeight: "18px",
                        }}
                      >
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
        )
      ) : (
        <Grid item xs={12}>
          <Grid
            style={{
              height: "400px",
              margin: "auto",
              alignItems: "center",
              display: "grid",
              justifyContent: "center",
              gap: "120px",
            }}
          >
            <img
              data-test-id="resource-image"
              className="no_data_found"
              src={noRecordFound}
            />
            <Typography variant="h6" className="no_data_found_text">
              {t("Ups!... no data found")}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
