// Customizable Area Start
import React, { useState } from "react";
import { Button, Grid, Typography,Chip,Box } from "@material-ui/core";
import { t } from "i18next";

interface HobbyObject {
    id: number
    name: string
    created_by_admin: boolean
    created_at: string
    updated_at: string
    interest_category: string
    status: string
}
interface HobbyDetailsDataInterface {
    title: string
    hobby: HobbyObject[]
}

const UserSkills = (hobbyDetails: HobbyDetailsDataInterface) => {
    const [showAll, setShowAll] = useState(true);
    const displayedHobbies = showAll ? hobbyDetails.hobby : hobbyDetails.hobby.slice(0, 3);
    const remainingCount = hobbyDetails.hobby.length - displayedHobbies.length;

    return (
        <>
            <Grid container spacing={0} style={{ borderBottom: '1px solid #E2E8F0' }}><Grid item xs={12} sm={6} className="text_first_tb_grid">
                <Typography className="text_first_tb">{t(hobbyDetails.title)}</Typography>
            </Grid>
                {!!hobbyDetails && hobbyDetails.hobby.length > 0 && (<Grid container item xs={12} sm={6} className="skils_second_tb_grid">
                    <Box>
                        {displayedHobbies.map((item: HobbyObject) => {
                            return (
                                <Chip 
                                    label={item.name}
                                    className="view_skills_chips"
                                />
                            )
                        })}
                        {remainingCount > 0 && !showAll &&
                            <Button data-test-id="more_button" variant="text" onClick={() => setShowAll(true)}
                                className="view_show_less view-more-btn">{t('+')}{remainingCount} {t('more')}
                            </Button>}
                        {showAll && hobbyDetails.hobby.length > 3 &&
                            <Button data-test-id="show-less-button" variant="text" onClick={() => setShowAll(false)}
                                className="view_show_less show-less-btn">{t('Show Less')}
                            </Button>}
                    </Box>
                </Grid>)}
            </Grid>
        </>
    )
}

export default UserSkills;
// Customizable Area End