import React, { useState } from "react";
import { TextField, Paper, Typography } from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { styled } from "@material-ui/styles";
import Checkbox from "@material-ui/core/Checkbox";

export const CustomTextFieldA = styled(TextField)(() => ({
  "& fieldset": {
    border: "none",
  },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment':
    {
      top: "8px",
      right: "9px",
      marginRight: "4px",
    },
  '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
    {
      paddingLeft: "12px",
    },
  "& .MuiAutocomplete-clearIndicator": {
    display: "none",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px 8px 0 0",
  },
  "&.disableCont": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
}));
export const CustomPaperA = styled(Paper)(() => ({
  borderRadius: "8px",
  overflowY: "auto",
  backgroundColor: "#fff",
  margin: 0,
  maxHeight: "40vh",
  width: "100%",
  border: "1px solid #CBD5E1",
  "&::-webkit-scrollbar": {
    width: "3px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#CFCFCF",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-tracks": {
    backgroundColor: "#F1F5F9",
    borderRadius: "4px",
  },
  "& .MuiAutocomplete-listbox": {
    padding: 0,
  },
  "& .MuiAutocomplete-option": {
    padding: "6px",
  },
  '& .MuiAutocomplete-option[aria-selected="true"]': {
    background: "none",
  },
}));

export const CustomCheckboxA = styled(Checkbox)(() => ({
  "&.Mui-checked": {
    color: "#5497FF",
  },
  "&.MuiCheckbox-root": {
    padding: "0px",
    margin: "0px",
  },
  "&.MuiIconButton-root": {
    padding: "0px",
    fontSize: "14px",
    borderColor: "#64748B",
  },
  "&.MuiIconButton-colorSecondary": {
    background: "none",
  },
}));

interface OptionsType {
  name: string;
  attributes: any;
}
interface InterfaceProps {
  options: OptionsType[];
  value: OptionsType[];
  handleConnectionList: Function;
}

const MultipleSelectAutoComplete = (props: InterfaceProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div>
        <Autocomplete
          data-test-id="multi-autocomplete"
          multiple
          disablePortal
          id="checkboxes-tags-demo"
          options={props.options}
          disableCloseOnSelect
          open={open}
          onChange={(event, value) => {
            props.handleConnectionList(value);
          }}
          value={props.value}
          getOptionSelected={(option, value) =>
            option.attributes.name === value.name
          }
          getOptionLabel={(option) => option.attributes.name}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          popupIcon={<ExpandMoreIcon />}
          PaperComponent={({ children }) => {
            return <CustomPaperA>{children}</CustomPaperA>;
          }}
          renderOption={(option, { selected }) => {
            return (
              <React.Fragment>
                <CustomCheckboxA
                  key={option.attributes.name}
                  icon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="19"
                        height="19"
                        rx="5.5"
                        stroke="#64748B"
                      />
                    </svg>
                  }
                  checkedIcon={
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="20" rx="6" fill="#5497FF" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.0611 5.42238C15.5183 5.73229 15.6376 6.35411 15.3277 6.81124L10.2432 14.3112C10.0771 14.5562 9.8111 14.715 9.51671 14.745C9.22232 14.7749 8.92977 14.673 8.71777 14.4665L4.80234 10.6536C4.40667 10.2683 4.39827 9.6352 4.78358 9.23953C5.16888 8.84386 5.80199 8.83546 6.19766 9.22077L9.25771 12.2007L13.6723 5.68895C13.9822 5.23182 14.604 5.11247 15.0611 5.42238Z"
                        fill="white"
                      />
                    </svg>
                  }
                  checked={selected}
                />
                <Typography
                  style={{
                    color: "#475569",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Nunito-Regular",
                    textAlign: "center",
                  }}
                >
                  {option.attributes.name}
                </Typography>
              </React.Fragment>
            );
          }}
          renderTags={(info) => {
            let formattedNames = "";

            if (info.length > 2) {
              formattedNames = `${info[0].name}, ${info[1].name}, +${
                info.length - 2
              }`;
            } else {
              formattedNames = `${info[0].name}`;
              if (info[1]) {
                formattedNames += `, ${info[1].name}`;
              }
            }
            return (
              <>
                <Typography
                  key={info[0].name}
                  style={{
                    color: "#1E293B",
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    fontFamily: "Nunito-Regular",
                    wordBreak: "break-word",
                  }}
                >
                  {formattedNames}
                </Typography>
              </>
            );
          }}
          renderInput={(params) => (
            <CustomTextFieldA
              {...params}
              variant="outlined"
              label=""
              placeholder=""
              className={"disableCont"}
              InputLabelProps={{ shrink: false }}
              InputProps={{
                ...params.InputProps,
                style: {
                  padding: "8px",
                  paddingLeft: "16px",
                  color: "#1E293B",
                  fontSize: "16px",
                  fontFamily: "Nunito-Regular",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default MultipleSelectAutoComplete;
