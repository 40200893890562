import React, { useEffect, useState } from "react";
// Customizable Area Start
import { Typography, Avatar, Divider, Box } from "@material-ui/core";
import { t } from "i18next";
import PostDetails from "../../../CommunityForum/src/components/PostDetails.web";

const UserPost = (props: any) => {
  const {
    posts,
    commentInput,
    onCommentChange,
    onCommentHandler,
    onLikeHandler,
    commentBoxVisibilityHandler,
    isCommentEnable,
    commentIndex,
    replyIndex,
    commentActiveIndex,
    isReplied,
    repiedIndex,
    isCommentActive,
    isReportModal,
    reportIndex,
    onRepliedHandler,
    onReplyHandler,
    onClickReplyComment,
    onClickCommentIndex,
    onReportModalClickHandler,
    allDownloadHandler,
    handleConfirmModalOpen,
    onMediaModalHandler,
    playVideo,
    playAudio,
    onShareInputHandler,
    onrhLanguageChange,
    onrhDescriptionChange,
    handleOnShareToResourceHub,
    handleTranslation,
    handleTransBackToOriginal,
    translationArray,
    rh_language,
    rh_description,
    languages
  } = props;

  const [isPostDetailsOpen, setPostDetailsOpen] = useState(false);
  const [postDetails, setPostDetails] = useState<any>(null);
  const [authorId, setAuthorId] = useState<any>("");

  useEffect(() => {
    if (postDetails) {
      const postDetail = posts.find((item: any) => item.id === postDetails.id);
      setPostDetails(() => postDetail);
    }
  }, [posts]);

  return !isPostDetailsOpen ? (
    <>
      {posts && posts.length > 0 ? (
        [...posts].reverse().map((item: any, i: number) => {
          let mediaArray = [
            ...item.attributes.images,
            ...item.attributes.videos,
            ...item.attributes.audios,
            ...item.attributes.documents,
          ];
          let arr = mediaArray;
          return (
            <Box
              className="user-recentPostCard"
              data-test-id="user-post-card"
              key={`box-post-${item.id}-`}
              sx={webStyles.boxStyle}
              onClick={(e) => {
                e.stopPropagation();
                setAuthorId(() => item.attributes.author_id);
                setPostDetails(() => item);
                setPostDetailsOpen(() => true);
              }}
            >
              <div className="user-recentPostBox">
                <Avatar src={item.attributes.author_image} />
                <div className="user-descriptionBox">
                  <div className="user-desc">
                    <Typography className="user-descTitle">
                      {item.attributes.author_name}
                    </Typography>
                    <div className="tag">{item.attributes.created_at}</div>
                  </div>
                  <Typography
                    data-test-id="test-post-id-two"
                    className="descText"
                  >
                    {item.attributes.description.length < 350 ? (
                      item.attributes.description
                    ) : (
                      <>
                        {item.attributes.description.substring(0, 350) + "... "}
                        <a data-test-id="more-id" className="seeMoreText">
                          {t("See more")}
                        </a>
                      </>
                    )}
                  </Typography>
                  <div className="recentTags"></div>
                </div>
                <div
                  data-test-id="report-btn"
                  className="report-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    onReportModalClickHandler(item.id);
                  }}
                  style={{ position: "relative", marginTop: 0 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M6 10C4.9 10 4 10.9 4 12C4 13.1 4.9 14 6 14C7.1 14 8 13.1 8 12C8 10.9 7.1 10 6 10ZM18 10C16.9 10 16 10.9 16 12C16 13.1 16.9 14 18 14C19.1 14 20 13.1 20 12C20 10.9 19.1 10 18 10ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                      fill="#64748B"
                    />
                  </svg>
                  {isReportModal && reportIndex == item?.id && (
                    <Box
                      test-id="reportbox01"
                      className="report-modal"
                      style={{ top: "85%" }}
                    >
                      <Typography
                        data-test-id="download-id"
                        className="report-text"
                        onClick={() => allDownloadHandler(arr)}
                      >
                        {t("Download")}
                      </Typography>
                    </Box>
                  )}
                </div>
              </div>
            </Box>
          );
        })
      ) : (
        <div style={{ textAlign: "center", width: "100%" }}>
          <Typography>{t("No posts yet")}</Typography>
        </div>
      )}
    </>
  ) : (
    <>
      <PostDetails
        state={{
          postDetails: postDetails,
          isReportModal: isReportModal,
          reportIndex: reportIndex,
          translationArray: translationArray,
          isCommentEnable: isCommentEnable,
          commentIndex: commentIndex,
          replyIndex: replyIndex,
          commentActiveIndex: commentActiveIndex,
          commentInput: commentInput,
          isCommentActive: isCommentActive,
          isReplied: isReplied,
          repiedIndex: repiedIndex,
          rh_language: rh_language,
          rh_description: rh_description,
        }}
        authorId={authorId}
        playAudio={playAudio}
        playVideo={playVideo}
        // onEditHandler={onEditHandler}
        onLikeHandler={onLikeHandler}
        onReplyHandler={onReplyHandler}
        // onDeleteHandler={onDeleteHandler}
        onCommentChange={onCommentChange}
        onRepliedHandler={onRepliedHandler}
        onCommentHandler={onCommentHandler}
        // handlePostDetails={handlePostDetails}
        allDownloadHandler={allDownloadHandler}
        onMediaModalHandler={onMediaModalHandler}
        onShareInputHandler={onShareInputHandler}
        onClickCommentIndex={onClickCommentIndex}
        onClickReplyComment={onClickReplyComment}
        handlePostDetailsBack={() => {
          setPostDetails(() => null);
          setPostDetailsOpen(() => false);
        }}
        handleConfirmModalOpen={handleConfirmModalOpen}
        // onCommentDeleteHandler={onCommentDeleteHandler}
        onReportModalClickHandler={onReportModalClickHandler}
        commentBoxVisibilityHandler={commentBoxVisibilityHandler}
        handleTransBackToOriginal={handleTransBackToOriginal}
        handleTranslation={handleTranslation}
        onrhDescriptionChange={onrhDescriptionChange}
        onrhLanguageChange={onrhLanguageChange}
        handleOnShareToResourceHub={handleOnShareToResourceHub}
        languages={languages}
      />
    </>
  );
};

const webStyles = {
  boxStyle: {
    marginBottom: "20px",
  },
};

// Customizable Area End

export default UserPost;
