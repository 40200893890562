import React, { useEffect, useState } from "react";
import {
    Typography,
} from "@material-ui/core";
import '../../assets/Community.scss';

const HashTag = ({ hashtagsData, handleHashTags }: any) => {
    const [visibleTags, setVisibleTags] = useState(hashtagsData);

    useEffect(() => {
        const updateVisibleTags = () => {
            const screenWidth = window.innerWidth;
            let limit = 12;

            if (screenWidth < 768) {
                limit = 5;  
            } else if (screenWidth < 1024) {
                limit = 8;  
            }

            setVisibleTags(hashtagsData.slice(0, limit));
        };

        updateVisibleTags();
        window.addEventListener("resize", updateVisibleTags);

        return () => window.removeEventListener("resize", updateVisibleTags);
    }, [hashtagsData]);

    return (
        <div className="tagbox">
            {visibleTags && visibleTags.map((item: any) => (
            <div test-id="tag-id" className="tags" key={item.id} onClick={() => handleHashTags(item.attributes.name, item.id)}>
                <Typography className="tag">{"#" + item.attributes.name}</Typography>
            </div>
            ))}
        </div>
    );
};

export default HashTag;
