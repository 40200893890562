import React from "react";
import PaginationBlock from "../../../../components/src/Pagination.web";
import { Box } from '@material-ui/core';

interface ExploreGroupPaginationInterface {
    current_page: number
    total_pages: number
}

interface ExploreGroupPaginationProps {
    groupList: any[]
    exploreGroupPagination: ExploreGroupPaginationInterface
    handleExploreGroupsPagination: Function
    userGroupPagination: ExploreGroupPaginationInterface
    handleUserGroupsPagination: any
    valueTab: number
    activeIndex: number
}

const ExploreGroupsPagination = (props: ExploreGroupPaginationProps) => {
    return (
        props.groupList.length > 0  ? <Box style={{marginTop: '-16px'}}>
            <PaginationBlock
                data-test-id="groups-pagination"
                page={props.activeIndex === 0 ? props.userGroupPagination.current_page : props.exploreGroupPagination.current_page}
                totalCount={props.activeIndex === 0 ? props.userGroupPagination.total_pages : props.exploreGroupPagination.total_pages}
                handlePagination={props.activeIndex === 0 ? props.handleUserGroupsPagination : props.handleExploreGroupsPagination}
            />
        </Box> : <div style={{marginTop: '-16px'}}></div>
    )
}

export default ExploreGroupsPagination