import React from "react";
import {
    Button
} from "@material-ui/core";
import '../../assets/Community.scss';
import { t } from "i18next";

export const Edit = ({ state, onEditApiHandler, disableHandler }: any) => {
    return (
        (state.postInput === '') ?
            <Button variant="contained" color="primary" onClick={disableHandler}>{t('Post')}</Button> :
            <Button variant="contained" color="primary" onClick={onEditApiHandler}>{t('Post')}</Button>
    )
}

export const Post = ({ state, postApiHandler,disableHandler }: any) => {
    return (
        (state.postInput === '') ?
            <Button variant="contained" color="primary" onClick={disableHandler}>{t('Post')}</Button> :
            <Button variant="contained" color="primary" onClick={postApiHandler}>{t('Post')}</Button>
    )
}

export const PostButton = ({ state, postApiHandler, onEditApiHandler, disableHandler }: any) => {
    console.log('functionAA', state)
    return (
        <>
            {
                state.isEdit ? <Edit state={state} onEditApiHandler={onEditApiHandler} disableHandler={disableHandler}/> : <Post state={state} postApiHandler={postApiHandler} disableHandler={disableHandler} />
            }
        </>
    );
};
