import React from "react";
import {
    Grid,
    Typography,
    Snackbar,
    Modal,
    Box,
    MenuItem,
    Avatar,
    TextField,
    Divider,
    Button,
    Backdrop,
    CircularProgress,
  } from "@material-ui/core";
  import MuiAlert from "@material-ui/lab/Alert";
  import Multiselect from 'multiselect-react-dropdown';
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 720,
    background: '#fff',
    border: "2px solid #fff",
    borderRadius: "8px",
    maxHeight: '100%',
    height: 'auto',
    "@media(max-width: 575px)": {
      width: '100% !important',
      top: 0,
      transform: 'unset',
      left: 0,
    },
    "@media(max-width: 768px)": {
      width: '85%',
    }
  }

import EditPost from "../../../CommunityForum/src/components/EditPost.web";
import UserPostDetail from "../../../CommunityForum/src/components/UserPostDetail.web";
import { t } from "i18next";
  
  export const CreatePostModal = ({isOpens, postModalHandlers, handleAttachModals, isAttachOpens, postMediaHandlers, handleCloseAttachmentModals,
  group, onFilteredHandlers, isEdit, postMedias, existedMedia, onExistedMediaHandlers, userPostInterests, openInterests, handleOpenInterests, 
  handleCloseInterests, postInterestHandlers, userInterests, onEditApiHandlers, postInputs, postApiHandlers, editPostId, handleCloseSnackbars,
  message, severity, showSnackbar, isLoadings, postInputHandlers, existedInterests, postInterestRemoves, disableHandler
  }:any) => {
        return(
            <Modal
            open={isOpens}
            onClose={postModalHandlers}
            disableScrollLock={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ borderRadius: "10px", boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)", }}
          >
            <>
              <Box style={style} className='modal-main'>
                <Box className='modal-title'>
                  <h3>
                    {t("Create a Post")}
                  </h3>
                  <div className='modal-attachment'>
                    <svg
                      data-test-id="attachment"
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 24 24"
                      fill="none"
                      onClick={() => handleAttachModals()}
                    >
                      <path
                        d="M19.4632 5.57598C18.7752 4.82598 17.5342 4.77998 16.7072 5.60698L8.60718 13.707C8.39718 13.917 8.39718 14.183 8.60718 14.393C8.81718 14.603 9.08318 14.603 9.29318 14.393L15.9932 7.69298C16.1818 7.51082 16.4344 7.41003 16.6966 7.41231C16.9588 7.41459 17.2096 7.51976 17.395 7.70516C17.5804 7.89057 17.6856 8.14139 17.6879 8.40358C17.6901 8.66578 17.5893 8.91838 17.4072 9.10698L10.7072 15.807C10.4788 16.0419 10.2057 16.2287 9.90391 16.3563C9.60212 16.4838 9.27782 16.5495 8.95018 16.5495C8.62255 16.5495 8.29824 16.4838 7.99645 16.3563C7.69466 16.2287 7.42152 16.0419 7.19318 15.807C6.95822 15.5786 6.77145 15.3055 6.64391 15.0037C6.51637 14.7019 6.45065 14.3776 6.45065 14.05C6.45065 13.7223 6.51637 13.398 6.64391 13.0963C6.77145 12.7945 6.95822 12.5213 7.19318 12.293L15.2932 4.19298C16.8602 2.62498 19.4082 2.57398 20.9232 4.20798C22.4752 5.77698 22.5202 8.31198 20.8932 9.82098L11.4072 19.307C9.21718 21.497 5.78318 21.497 3.59318 19.307C1.40318 17.117 1.40318 13.683 3.59318 11.493L11.6932 3.39298C11.8818 3.21082 12.1344 3.11003 12.3966 3.11231C12.6588 3.11459 12.9096 3.21976 13.095 3.40516C13.2804 3.59057 13.3856 3.84138 13.3879 4.10358C13.3901 4.36578 13.2893 4.61838 13.1072 4.80698L5.00718 12.907C3.59718 14.317 3.59718 16.483 5.00718 17.893C6.41718 19.303 8.58318 19.303 9.99318 17.893L19.4932 8.39298L19.5242 8.36298C20.2742 7.67598 20.3202 6.43398 19.4932 5.60698C19.483 5.59685 19.473 5.58652 19.4632 5.57598Z"
                        fill="#94A3B8" />
                    </svg>
                    <Typography className='descText' onClick={handleAttachModals}>{t("Attachment")}</Typography>
                  </div>
                  {isAttachOpens &&
                    <div style={{
                      display: "flex",
                      width: "96px",
                      padding: "0 8px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "8px",
                      borderRadius: "4px",
                      background: "#FFF",
                      boxShadow: "0px 2px 3px 3px rgba(0, 0, 0, 0.08)",
                      position: "absolute",
                      top: '50px',
                      right: '5px',
                      zIndex: 9,
                      cursor: "pointer"
                    }}>
                      <MenuItem className="filterformMenu">
                        <input data-test-id="image" name="images" type='file' accept="image/png, image/jpeg, image/jpg" style={{ opacity: 0, zIndex: 9 }} multiple 
                        onChange={(e) => postMediaHandlers(e, 'image')} />
                        <span
                          style={{ position: 'absolute', display: "flex", gap: "5px", cursor: "pointer" }}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM9.42667 7.90667L7.42667 10.4867L6 8.76L4 11.3333H12L9.42667 7.90667Z" fill="#475569" />
                          </svg>
                          {t("Image")}
                        </span>
                      </MenuItem>
                      <MenuItem className="filterformMenu">
                        <input data-test-id="video" name="videos" type='file' accept="video/mp4, video/mov, video/wmv, video/flv, video/avi, video/mkv, video/webm"
                         style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => postMediaHandlers(e, 'video')} />
                        <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M12.0002 6.98669V4.00002C12.0002 3.26669 11.4002 2.66669 10.6668 2.66669H2.66683C1.9335 2.66669 1.3335 3.26669 1.3335 4.00002V12C1.3335 12.7334 1.9335 13.3334 2.66683 13.3334H10.6668C11.4002 13.3334 12.0002 12.7334 12.0002 12V9.01335L14.6668 11.6667V4.33335L12.0002 6.98669ZM10.6668 12H2.66683V4.00002H10.6668V12ZM7.74683 7.66669L6.00016 10L4.92016 8.55335L3.3335 10.6667H10.0002L7.74683 7.66669Z" fill="#475569" />
                          </svg>
                          {t("Video")}
                        </span>
                      </MenuItem>
                      <MenuItem className="filterformMenu">
                        <input data-test-id="audio" name="audios" type='file' accept="audio/mp3, audio/mpeg" style={{ opacity: 0, zIndex: 9 }} multiple
                         onChange={(e) => postMediaHandlers(e, 'audio')} />
                        <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M7.16651 12.6667C7.58873 12.6667 7.94428 12.5223 8.23317 12.2334C8.52206 11.9445 8.66651 11.5889 8.66651 11.1667V8.66671H10.6665V7.33338H7.99984V9.91671C7.87762 9.82782 7.74695 9.76382 7.60784 9.72471C7.46873 9.6856 7.32162 9.66626 7.16651 9.66671C6.74428 9.66671 6.38873 9.81115 6.09984 10.1C5.81095 10.3889 5.66651 10.7445 5.66651 11.1667C5.66651 11.5889 5.81095 11.9445 6.09984 12.2334C6.38873 12.5223 6.74428 12.6667 7.16651 12.6667ZM3.99984 14.6667C3.63317 14.6667 3.31917 14.536 3.05784 14.2747C2.7965 14.0134 2.66606 13.6996 2.66651 13.3334V2.66671C2.66651 2.30004 2.79717 1.98604 3.0585 1.72471C3.31984 1.46338 3.63362 1.33293 3.99984 1.33338H9.33317L13.3332 5.33338V13.3334C13.3332 13.7 13.2025 14.014 12.9412 14.2754C12.6798 14.5367 12.3661 14.6672 11.9998 14.6667H3.99984ZM8.66651 6.00004V2.66671H3.99984V13.3334H11.9998V6.00004H8.66651Z" fill="#475569" />
                          </svg>
                          {t("Audio")}
                        </span>
                      </MenuItem>
                      <MenuItem className="filterformMenu">
                        <input data-test-id="document" name="doc" accept="application/pdf, application/doc, application/csv, application/msword" type='file' 
                        style={{ opacity: 0, zIndex: 9 }} multiple onChange={(e) => postMediaHandlers(e, 'document')} />
                        <span style={{ position: 'absolute', display: "flex", gap: "5px" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M12.9753 3.71728C12.5166 3.21728 11.6893 3.18661 11.138 3.73795L5.73796 9.13795C5.59796 9.27795 5.59796 9.45528 5.73796 9.59528C5.87796 9.73528 6.05529 9.73528 6.19529 9.59528L10.662 5.12861C10.7877 5.00718 10.9561 4.93998 11.1309 4.9415C11.3057 4.94302 11.4729 5.01313 11.5965 5.13674C11.7201 5.26034 11.7902 5.42755 11.7917 5.60235C11.7933 5.77714 11.7261 5.94555 11.6046 6.07128L7.13796 10.5379C6.98573 10.6946 6.80364 10.8191 6.60245 10.9041C6.40125 10.9892 6.18505 11.033 5.96663 11.033C5.7482 11.033 5.532 10.9892 5.33081 10.9041C5.12961 10.8191 4.94752 10.6946 4.79529 10.5379C4.63865 10.3857 4.51414 10.2036 4.42911 10.0024C4.34408 9.80124 4.30027 9.58504 4.30027 9.36661C4.30027 9.14819 4.34408 8.93199 4.42911 8.73079C4.51414 8.5296 4.63865 8.34751 4.79529 8.19528L10.1953 2.79528C11.24 1.74995 12.9386 1.71595 13.9486 2.80528C14.9833 3.85128 15.0133 5.54128 13.9286 6.54728L7.60463 12.8713C6.14463 14.3313 3.85529 14.3313 2.39529 12.8713C0.935293 11.4113 0.935293 9.12195 2.39529 7.66195L7.79529 2.26195C7.92103 2.14051 8.08943 2.07331 8.26423 2.07483C8.43902 2.07635 8.60623 2.14646 8.72984 2.27007C8.85344 2.39367 8.92356 2.56088 8.92508 2.73568C8.92659 2.91048 8.8594 3.07888 8.73796 3.20461L3.33796 8.60461C2.39796 9.54461 2.39796 10.9886 3.33796 11.9286C4.27796 12.8686 5.72196 12.8686 6.66196 11.9286L12.9953 5.59528L13.016 5.57528C13.516 5.11728 13.5466 4.28928 12.9953 3.73795C12.9885 3.7312 12.9818 3.72431 12.9753 3.71728Z" fill="#475569" />
                          </svg>
                          {t("Document")}
                        </span>
                      </MenuItem>
                    </div>
                  }
                </Box>
                <Box data-test-id="close-attachment" className='modal-body' onClick={() => handleCloseAttachmentModals()}>
                  <Box className='profile-wrap'>
                    <Avatar src={group && group.attributes && group.attributes.icon_image} />
                    <span className="attachmentText">{t("Hey, share what’s on your mind...")}</span>
                  </Box>
                  <Divider />
                  <Box className='input-box'>
                    <TextField className='input attachmentText'
                      test-id="post-input"
                      placeholder={t("Write description here")}
                      value={postInputs}
                      onChange={postInputHandlers}
                      multiline
                      rows={2}
                      maxRows={4}
                    />
                  </Box>
                </Box>

                <Box className="media-modal-scroll">
                {isEdit ?
                  <EditPost postMedia={postMedias} existedMedia={existedMedia} onFilteredHandler={onFilteredHandlers}
                   onExistedMediaHandler={onExistedMediaHandlers} />
                  :
                    <UserPostDetail postMedia={postMedias} onFilteredHandler={onFilteredHandlers} />
                }
                </Box>
                <Box className='modal-footer' onClick={handleCloseAttachmentModals}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid style={{ margin: "auto", maxHeight: "250px" }} item md={6} sm={6} lg={6} xs={12}>
                    <Multiselect
                      selectedValues={existedInterests}
                      displayValue="key"
                      groupBy="cat"
                      placeholder={t('Select interest')}
                      style={{ cursor: "pointer" }}
                      onRemove={postInterestRemoves}
                      onSelect={postInterestHandlers}
                      options={userInterests}
                      showCheckbox
                      className="select-interest-post"
                    />
                    </Grid>
                    <Grid item className="web-post-btn" xs={12} sm={6}>
                      {postInputs === '' ?
                        <Button className="disable-post-btn" variant="contained" color="primary" onClick={disableHandler}>{t("Post")}</Button> :
                        <Button data-test-id="post" variant="contained" color="primary" 
                        onClick={() => isEdit ? onEditApiHandlers(group.id, editPostId) : postApiHandlers(group.id)}>{t("Post")}</Button>
                      }
                    </Grid>
                  </Grid>
                </Box>
                <Box data-test-id="handleCloseAttachment" className='modal-footer-responsive' onClick={() => handleCloseAttachmentModals()}>
                  <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={6}>
                      {postInputs === '' ?
                        <Button className="disable-post-btn" variant="contained" color="primary" onClick={disableHandler}>{t("Post")}</Button> :
                        <Button data-test-id="postBtn" variant="contained" color="primary" 
                        onClick={() => isEdit ? onEditApiHandlers(group.id, editPostId) : postApiHandlers(group.id)}>{t("Post")}</Button>
                      }
                    </Grid>
                  </Grid>
                </Box>

              </Box>
              <Snackbar
                style={{ zIndex: 9999 }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={showSnackbar}
                autoHideDuration={5000}
                onClose={handleCloseSnackbars}
              >
                <MuiAlert
                  onClose={handleCloseSnackbars}
                  severity={severity}
                >
                  {message}
                </MuiAlert>
              </Snackbar>
              <Backdrop className="backdrop-loader" open={isLoadings} >
                <CircularProgress color="inherit" />
              </Backdrop>
            </>
  </Modal>
          )
    }