import * as React from "react";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  IconButton,
  Popover,
  Link,
  Grid,
  Card,
  styled,
} from "@material-ui/core";
import { GroupMediaData } from "./groupMediaData.web";
import { t } from "i18next";
import FilterListIcon from "@material-ui/icons/FilterList";
import Skeleton from "@material-ui/lab/Skeleton";
import LangDropDown from "../../../memberdirectory/src/components/LangDropdown.web";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTab = styled(Tab)({
  color: "#475569",
  textTransform: "none",
  fontWeight: 400,
  fontFamily: "Nunito-Regular !important",
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "22px",
  minWidth: "auto",
  padding: "0 2px",

  '&.Mui-selected' : {
      fontWeight: 600,
      color: "#5497FF",
      borderBottom: "2px solid #5497FF"
  }
})

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const GroupMediaTabs = ({
  onDownloadHandler,
  groupMediaData,
  handleEndPoint,
  groupId,
  handlePostDetails,
  loading,
}: any) => {
  const [value, setValue] = React.useState(0);
  const handleChange: any = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSelectedValue = () => {
    if (value === 0) {
      return "all";
    } else if (value === 1) {
      return "image";
    } else if (value === 2) {
      return "video";
    } else if (value === 3) {
      return "audio";
    } else if (value === 4) {
      return "document";
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const loadingSkeleton = () => {
    return Array.from({ length: 7 }).map((_, index) => (
      <Grid
        key={index}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        style={{ height: "320px" }}
      >
        <Card
          style={{
            height: "auto",
            borderRadius: "8px",
            backgroundColor:
              "linear-gradient(rgb(231, 239, 252) 100%, rgb(255, 255, 255) 100%)",
            boxShadow: "2px 0px 8px 3px #00000014",
            justifyContent: "center",
          }}
        >
          <Skeleton animation="wave" variant="rect" height={200} />
          <Box
            padding={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
              <Skeleton
                animation="wave"
                variant="rect"
                width={30}
                height={30}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width={100}
                height={16}
              />
            </Box>
            <Skeleton animation="wave" variant="text" width={50} height={30} />
          </Box>
          <Box
            paddingLeft={1}
            paddingRight={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
          >
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={16}
            />
            <Skeleton animation="wave" variant="text" width="70%" height={16} />
          </Box>
        </Card>
      </Grid>
    ));
  };

  return (
    <Box sx={{ width: "100%", padding: "24px 0 0" }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ borderBottom: "1px solid #0000001f", paddingBottom: "16px" }}
      >
        <Tabs
          style={{ border: 0 }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="tabsContent"
        >
          <StyledTab
            data-test-id="all"
            className="tabLabel"
            label={t("All")}
            {...a11yProps(0)}
            onClick={() =>
              handleEndPoint(`group_posts/${groupId}/group_images`)
            }
          />
          <StyledTab
            data-test-id="images"
            className="tabLabel"
            label={t("Images")}
            {...a11yProps(2)}
            onClick={() =>
              handleEndPoint(`group_posts/${groupId}/group_images`)
            }
          />
          <StyledTab
            data-test-id="videos"
            className="tabLabel"
            label={t("Videos")}
            {...a11yProps(3)}
            onClick={() =>
              handleEndPoint(`group_posts/${groupId}/group_videos`)
            }
          />
          <StyledTab
            data-test-id="audios"
            className="tabLabel"
            label={t("Audios")}
            {...a11yProps(4)}
            onClick={() =>
              handleEndPoint(`group_posts/${groupId}/group_audios`)
            }
          />
          <StyledTab
            data-test-id="documents"
            className="tabLabel"
            label={t("Documents")}
            {...a11yProps(5)}
            onClick={() =>
              handleEndPoint(`group_posts/${groupId}/group_documents`)
            }
          />
        </Tabs>
        <Box>
          <IconButton
            data-test-id="media-filter-language-icon"
            onClick={handleOpen}
          >
            <FilterListIcon />
          </IconButton>

          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            className="media-filter-language"
            data-test-id="media-filter-language"
          >
            <div style={{ padding: "16px", minWidth: "250px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="subtitle1">
                  <FilterListIcon
                    style={{ marginRight: 8, verticalAlign: "middle" }}
                  />
                  Filter
                </Typography>
                <Link component="button" variant="body2" color="primary">
                  Clear All
                </Link>
              </div>

              <Typography
                variant="body2"
                style={{ marginTop: 8, color: "#475569", marginBottom: "4px" }}
              >
                Select Content Language
              </Typography>
              <LangDropDown
                initialValue={{
                  attributes: {
                    name: "demo",
                  },
                }}
                options={[]}
                onChange={() => {}}
                placeHolder={"Language"}
              />
            </div>
          </Popover>
        </Box>
      </Box>
      {loading ? (
        <Box marginTop="20px">
          <Grid container spacing={2}>
            {loadingSkeleton()}
          </Grid>
        </Box>
      ) : (
        <>
          <CustomTabPanel value={value} index={0}>
            <GroupMediaData
              groupMediaData={groupMediaData}
              type={"images"}
              handlePostDetails={handlePostDetails}
              onDownloadHandler={onDownloadHandler}
              selectedTab={handleSelectedValue()}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <GroupMediaData
              groupMediaData={groupMediaData}
              type={"images"}
              handlePostDetails={handlePostDetails}
              onDownloadHandler={onDownloadHandler}
              selectedTab={handleSelectedValue()}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <GroupMediaData
              groupMediaData={groupMediaData}
              type={"videos"}
              handlePostDetails={handlePostDetails}
              onDownloadHandler={onDownloadHandler}
              selectedTab={handleSelectedValue()}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <GroupMediaData
              groupMediaData={groupMediaData}
              type={"audios"}
              handlePostDetails={handlePostDetails}
              onDownloadHandler={onDownloadHandler}
              selectedTab={handleSelectedValue()}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <GroupMediaData
              groupMediaData={groupMediaData}
              type={"documents"}
              handlePostDetails={handlePostDetails}
              onDownloadHandler={onDownloadHandler}
              selectedTab={handleSelectedValue()}
            />
          </CustomTabPanel>
        </>
      )}
    </Box>
  );
};
